import {createSelector} from '@reduxjs/toolkit';
import {Door as OGDoor} from 'components/customer/Materials/entity';
import {
    MaterialSearchRequest,
    useListDoorsQuery,
    useSearchDoorsQuery,
} from 'components/customer/Materials/store/materialApi';
import React, {useCallback, useMemo} from 'react';
import {Image} from 'shared/Image';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {APIResponse} from 'store/customer/api';
import styled from 'styled-components';
import {
    doorSet,
    materialTypeSet,
    selectDoor,
    selectMaxIndexSeen,
    selectedMenuSet,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {Spinner} from 'react-bootstrap';
import {shallowEqual} from 'react-redux';
import {CBCButton} from 'shared/helpers';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import {Type} from 'components/customer/AdvancedMaterials/entity/Type';

interface Params extends MaterialSearchRequest {
    materialType: string;
}

interface DataProps {
    columns: number;
    selected: boolean;
    searchParams: Params;
    setSelected: () => void;
    searchText: string;
    type: Type;
}
interface DoorProps {
    rowIndex?: number;
    columnIndex?: number;
    style: React.CSSProperties;
    data: DataProps;
}

interface ApiResponseWithLoadingState extends APIResponse<OGDoor[]> {
    isLoading: boolean;
    isFetching: boolean;
}

export const Door = ({style, rowIndex, columnIndex, data}: DoorProps) => {
    const dispatch = useAppDispatch();
    const selectedDoor = useAppSelector(selectDoor, shallowEqual);

    const index = useMemo(
        () => columnIndex + rowIndex * data.columns,
        [columnIndex, rowIndex, data]
    );
    const seenIndex = useAppSelector(selectMaxIndexSeen);
    const {pageIndex, currentPage} = useMemo(() => {
        if (!data.selected) {
            return {
                pageIndex: index,
                currentPage: 1,
            };
        }

        const pageSize = data.searchParams.pageSize;
        const pageIndex = index % pageSize;
        const currentPage = Math.floor(index / pageSize) + 1;

        // console.log(pageIndex, index, currentPage);

        return {
            pageIndex,
            currentPage,
        };
    }, [seenIndex, index, data]);

    const doorSelector = useCallback(
        createSelector(
            (res: ApiResponseWithLoadingState) => res,
            (res: ApiResponseWithLoadingState, index: number) => index,
            (result, index) => {
                if (result && result.data && result.data) {
                    const data = result.data.filter((_, i) => index == i);

                    return {
                        data: {
                            data,
                            pagination: result.pagination,
                        } as APIResponse<OGDoor[]>,
                        isLoading: result.isLoading,
                        isFetching: result.isFetching,
                    };
                }

                return {
                    data: {
                        data: [],
                        pagination: result?.pagination,
                    } as APIResponse<OGDoor[]>,
                    isLoading: result.isLoading,
                    isFetching: result.isFetching,
                };
            }
        ),
        []
    );

    const {
        data: doorData,
        isLoading,
        isFetching,
    } = useListDoorsQuery(
        {
            ...data.searchParams,
            currentPage,
        },
        {
            skip: data?.searchText.length > 0,
            selectFromResult: (result) => {
                return doorSelector(
                    {
                        ...result?.data,
                        isLoading: result.isLoading,
                        isFetching: result.isFetching,
                    },
                    pageIndex
                );
            },
        }
    );
    const {
        data: searchData,
        isLoading: isSearchLoading,
        isFetching: isSearchFetching,
    } = useSearchDoorsQuery(
        {
            ...data.searchParams,
            doorName: data.searchText,
            currentPage,
        },
        {
            skip: data?.searchText.length == 0,
            selectFromResult: (result) =>
                doorSelector(
                    {
                        ...result?.data,
                        isLoading: result.isLoading,
                        isFetching: result.isFetching,
                    },
                    pageIndex
                ),
        }
    );

    const selectType = useCallback(() => {
        data.setSelected();
    }, [data.setSelected]);

    const door = useMemo(() => {
        if (
            data?.searchText.length == 0 &&
            doorData &&
            doorData.data &&
            doorData.data.length > 0
        ) {
            return doorData.data[0];
        }

        if (
            data?.searchText.length > 0 &&
            searchData &&
            searchData.data &&
            searchData.data.length > 0
        ) {
            return searchData.data[0];
        }

        return undefined;
    }, [data, doorData, searchData]);

    const showMore = useMemo(() => {
        let check = 5;

        // data.columns change depending on the maxWidth of the container so we are recalculating the number of columns e.g zooming
        // every time this is changing we are calling our door api with page_size = column - 1 so we can alocate 1 column for the show all button
        if (data?.columns <= check) {
            check = check - 1;
        }

        if (data?.searchText.length > 0) {
            if (searchData && searchData.pagination) {
                return (
                    !data.selected &&
                    searchData.pagination.total_count > check &&
                    index == searchData.pagination.page_size
                );
            }
        }

        if (doorData && doorData.pagination) {
            return (
                !data.selected &&
                doorData.pagination.total_count > check &&
                index == doorData.pagination.page_size
            );
        }

        return false;
    }, [data, doorData, searchData, index]);

    const selectDoorHandler = useCallback(
        (door: OGDoor, showMore: boolean) => () => {
            if (!showMore) {
                dispatch(materialTypeSet(data.type));
                dispatch(doorSet(door));
                dispatch(selectedMenuSet(Menu.MATERIAL, true));
            }
        },
        [data.type]
    );

    if (isLoading || isFetching || isSearchLoading || isSearchFetching) {
        return (
            <div style={style}>
                <SpinnerContainer>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </SpinnerContainer>
            </div>
        );
    }

    if (typeof door === 'undefined' && !showMore) {
        return null;
    }

    return (
        <Container style={style}>
            <Card
                $variant={
                    door && selectedDoor && selectedDoor.id == door.id
                        ? 'primary'
                        : 'secondary'
                }
                $hover={!showMore}
                $active={
                    selectedDoor && door ? selectedDoor.id == door.id : false
                }>
                {showMore ? (
                    <MoreButton onClick={selectType}>SHOW ALL</MoreButton>
                ) : (
                    <>
                        <ButtonContainer className="button-container">
                            <Button
                                onClick={selectDoorHandler(door, showMore)}
                                type="button"
                                iconName="Button-Tick.svg"
                                className="job-button button-blue">
                                Use Door
                            </Button>
                        </ButtonContainer>
                        <DoorImage src={door?.image?.name} potrait={true} />
                        <Name>{door?.name}</Name>
                    </>
                )}
            </Card>
        </Container>
    );
};

const ButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    display: none;
`;

const Button = styled((props) => <CBCButton {...props} />)`
    padding: 3px;
    font-size: 0.85em;

    > img {
        width: 21px !important;
    }
`;

const Container = styled.div`
    padding: 5px 0;
`;

export const Card = styled.div<{
    $hover?: boolean;
    $active?: boolean;
    $variant?: string;
}>`
    height: 100%;
    border: 2px solid rgb(0, 0, 0, 0);
    padding: 10px 5px 5px;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    position: relative;

    ${({$active = false, $variant = 'secondary'}) =>
        $active
            ? `border: 2px solid rgb(var(--${
                  $variant == 'primary' ? 'primary' : 'secondary'
              }_colour));`
            : ''}

    ${({$hover = false}) =>
        $hover
            ? '&:hover {border: 2px solid rgb(var(--secondary_colour));}'
            : ''}

    &:hover .button-container {
        display: flex;
    }
`;

export const DoorImage = styled(Image)`
    flex: 1;
    max-width: 100% !important;
    height: 80%;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        width: 100% !important;
    }
`;

export const Name = styled.label`
    color: gray;
    display: block;
    font-weight: 500;
    font-size: 0.9em;
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
    height: fit-content;
    overflow: visible;
`;

const MoreButton = styled.button`
    height: 156px;
    width: 120px !important;
    border: 0;
    border-radius: 8px;
    background: rgb(var(--secondary_colour));
    color: white;
    font-size: 1.3em;
    font-weight: 900;
    line-height: 1em;
    word-spacing: 10em;

    &:hover,
    &:focus,
    &:active {
        background: rgb(var(--primary_colour));
    }
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
